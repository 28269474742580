import React from 'react';
import cn from 'classnames';
import Head from 'next/head';
import btnStyles from '@dealroadshow/uikit/core/components/Button/button.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import Button from '@dealroadshow/uikit/core/components/Button';
import { useConsentContext } from '@/dataroom/application/ConsentContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import consentStyles from './consent.scss';

function Consent() {
  const { acceptConsent } = useConsentContext();
  const { tenant } = useDataroomTenantContext();

  const header = DataroomTenantConfig.fromCode(tenant).name;

  return (
    <>
      <Head>
        <title>{ `${ header } | Deal Manager Portal` }</title>
      </Head>
      <div className={ cn(consentStyles.consentWrp, consentStyles[`tenant${ tenant }`]) }>

        <div className={ consentStyles.header }>
          <h2>{ header }</h2>
          <h3>Verified document notarization and delivery.</h3>
        </div>

        <div className={ consentStyles.contentWrp }>
          <div className={ consentStyles.content }>
            <h1>
              Consent to Electronic Delivery
            </h1>
            <p>VeriSend is a secure electronic document delivery and notary service.
            </p>
            <p>
              By clicking "I Agree", you consent to accepting delivery to you of any offering documents or
              offering-related materials of any issuer by means of electronic delivery directly through the VeriSend
              portal where you can access, download and/or print such documents.
            </p>
            <p>
              Notification of electronic delivery will be provided to you via email.
              Your consent to electronic delivery may be
              revoked at any time by amending your personal profile, or by contacting a VeriSend 24x7 fanatical
              support
              representative. Revocation of consent must be on an all or nothing basis and may not be revoked in part.
              You
              acknowledge that you have all requisite authority,
              either individually or on behalf of your company, to consent
              to electronic delivery and that you have agreed to the above terms.
            </p>
            <p>
              <Button
                className={ cn(btnStyles.action, spacesStyles.mrn, consentStyles.agreeButton) }
                onClick={ acceptConsent }
                dataTest="acceptConsent"
              >
                I Agree
              </Button>
            </p>
            <p>
              <a
                className={ cn(consentStyles.learnMoreLink) }
                data-test="learnMore"
                href={ `${ window.location.protocol }//${ DataroomTenantConfig.fromCode(tenant).hostname }` }
              >
                Learn more
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Consent;
