import {
  indexCellCallback,
  ratingCellCallback,
  yieldCellCallback,
  currencyCellCallback,
  benchmarkCellCallback,
  numberCellCallback,
  displayNameCellCallback,
  tenorCellCallback,
  couponCellCallback,
} from '@/allocate/ui/components/shared/Tables/cellCallbacks';
import {
  classSubscriptionLevelCellCallback,
  interestOnlyCellCallback,
  priceCellCallback,
  spreadCellCallback,
} from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsTable/cellCallbacks';
import { IColumn } from '@dealroadshow/uikit';
import { ASSET_BACKED_SECURITY_DEBT_ID } from '@/allocate/application/config';
import { IDebtTableColumnsConfig } from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsTable/interfaces';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

const columns = ({
  hasOrders,
  hasDollarPrice,
  hasSpreadAtWhisper,
  hasSpreadAtGuidance,
  hasInterestOnly,
  debtTypeId,
}: IDebtTableColumnsConfig): IColumn[] => ([
  {
    name: 'index',
    title: '#',
    tooltipContent: 'Reference Number',
    width: 38,
    cellCallback: indexCellCallback,
  },
  {
    name: 'className',
    title: 'Class',
    tooltipContent: 'Class Name',
  },
  {
    name: 'currency',
    title: 'CCY',
    tooltipContent: 'Currency',
    cellCallback: currencyCellCallback,
  },
  {
    name: 'totalDealSize',
    title: 'Size',
    tooltipContent: 'Size',
    className: alignStyles.alignRight,
    cellCallback: numberCellCallback,
  },
  {
    name: 'tenorWALYears',
    title: debtTypeId === ASSET_BACKED_SECURITY_DEBT_ID ? 'Wal' : 'Tenor',
    tooltipContent: debtTypeId === ASSET_BACKED_SECURITY_DEBT_ID ? 'Weighted Average Life' : 'Tenor',
    className: alignStyles.alignRight,
    cellCallback: tenorCellCallback,
  },
  {
    name: 'debtRanking',
    title: 'Rank',
    tooltipContent: 'Rank Name',
    cellCallback: displayNameCellCallback,
  },
  {
    name: 'rating',
    title: 'MO / SP / FI / OT',
    tooltipContent: 'Moody’s, S&P, Fitch, Other Rating Agencies',
    cellCallback: ratingCellCallback,
  },
  {
    name: 'debtCouponType',
    title: 'CPN Type',
    tooltipContent: 'Coupon Type',
    cellCallback: displayNameCellCallback,
  },
  {
    name: 'benchmark',
    title: 'BNCH',
    tooltipContent: 'Benchmark',
    className: alignStyles.alignRight,
    cellCallback: benchmarkCellCallback,
  },
  {
    name: 'spreadAtWhisper',
    title: 'SPRD@W',
    tooltipContent: 'Spread at Whisper',
    className: alignStyles.alignRight,
    isVisible: hasSpreadAtWhisper,
    cellCallback: spreadCellCallback,
  },
  {
    name: 'spreadAtGuidance',
    title: 'SPRD@G',
    tooltipContent: 'Spread at Guidance',
    className: alignStyles.alignRight,
    isVisible: hasSpreadAtGuidance,
    cellCallback: spreadCellCallback,
  },
  {
    name: 'spreadAtLaunch',
    title: 'SPRD',
    tooltipContent: 'Spread',
    className: alignStyles.alignRight,
    cellCallback: spreadCellCallback,
  },
  {
    name: 'coupon',
    title: 'CPN',
    tooltipContent: 'Coupon',
    className: alignStyles.alignRight,
    cellCallback: couponCellCallback,
  },
  {
    name: 'yield',
    title: 'YLD',
    tooltipContent: 'Yield',
    className: alignStyles.alignRight,
    cellCallback: yieldCellCallback,
  },
  {
    name: 'dollarPrice',
    title: 'Price',
    tooltipContent: 'Price',
    className: alignStyles.alignRight,
    isVisible: hasDollarPrice,
    cellCallback: priceCellCallback,
  },
  {
    name: 'debtRegistration',
    title: 'Registration',
    tooltipContent: 'Registration Type',
    className: alignStyles.alignRight,
    cellCallback: displayNameCellCallback,
  },
  {
    name: 'interestOnly',
    title: 'I/O',
    tooltipContent: 'Interest Only',
    className: alignStyles.alignCenter,
    isVisible: hasInterestOnly,
    cellCallback: interestOnlyCellCallback,
  },
  {
    name: 'subscriptionLevel',
    title: 'SUBX',
    tooltipContent: 'Subscription Level',
    className: alignStyles.alignRight,
    isVisible: hasOrders,
    cellCallback: classSubscriptionLevelCellCallback,
  },
]);

export default columns;
