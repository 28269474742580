import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@/Framework/Router/Next/Link';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import StepWrapper from '@/allocate/ui/components/shared/Upload/StepWrapper';
import OwnerField from '@/allocate/ui/components/shared/Upload/common/OwnerField';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { getDrsUploadUrl, steps } from '@/allocate/application/Allocations/Upload/config';
import { getRoadshowName } from '@/dealroadshow/application/actions/analytics/metadata/selectors';
import { initialValues } from './constants';
import validate from './validation';

import { DRS_ALLOCATIONS_ALREADY_EXIST_WARNING } from '@/allocate/ui/components/shared/Upload/constants';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { IImportModalState, IOwnerFormValues } from './interfaces';

import ownerIcon from '@/allocate/ui/assets/ownerIcon.svg';
import styles from './addOwner.scss';

const AddOwner = () => {
  const roadshowName = useSelector(getRoadshowName);

  const {
    linkedRoadshowId,
    checkForUploadedAllocations,
    importDrsAllocations,
  } = useUploadContext();

  const [importModalState, setImportModalState] = useState<IImportModalState>({
    isVisible: false,
    ownerId: null,
  });

  const handleImportModalConfirm = async () => {
    setImportModalState({
      isVisible: false,
      ownerId: null,
    });

    await importDrsAllocations(importModalState.ownerId, roadshowName, true);
  };

  const handleImportModalCancel = () => {
    setImportModalState({
      isVisible: false,
      ownerId: null,
    });
  };

  const handleSubmit = async ({ ownerId }: IOwnerFormValues): Promise<void> => {
    const hasAllocations = await checkForUploadedAllocations();

    if (hasAllocations) {
      setImportModalState({
        isVisible: true,
        ownerId,
      });
    } else {
      await importDrsAllocations(ownerId, roadshowName);
    }
  };

  const renderForm: TFinalFormRenderProp<IOwnerFormValues> = ({
    submitting,
    submitFailed,
    invalid,
  }) => (
    <>
      <div className={ styles.wrapper }>
        <Modal
          className={ styles.modalOverlay }
          modalDialogClassName={ styles.modal }
          onCloseClicked={ handleImportModalCancel }
          isVisible={ importModalState.isVisible }
          title="Are You Sure?"
          dataTest="areYouSureLinkModal"
          footer={ (
            <>
              <Button
                onClick={ handleImportModalConfirm }
                variant={ ButtonVariantType.action }
                title="Yes"
                dataTest="areYouSureLinkModalYesButton"
              />
              <Button
                variant={ ButtonVariantType.text }
                onClick={ handleImportModalCancel }
                title="Cancel"
                dataTest="areYouSureLinkModalCancelButton"
              />
            </>
          ) }
        >
          { DRS_ALLOCATIONS_ALREADY_EXIST_WARNING }
        </Modal>
        <div className={ styles.content }>
          <img src={ ownerIcon } className={ styles.icon } alt="addOwnerIcon" />
          <div className={ styles.title }>
            Add Owner
          </div>
          <div className={ styles.description }>
            Add the Owner of this allocation to designate the Account that owns this data
          </div>
          <div className={ styles.fieldContainer }>
            <OwnerField />
          </div>
        </div>
      </div>
      <div className={ styles.buttons }>
        <Link to={ getDrsUploadUrl(steps.linkAccounts, linkedRoadshowId) }>
          <Button
            variant={ ButtonVariantType.text }
            dataTest="addOwnerBackButton"
          >
            Back
          </Button>
        </Link>
        <Button
          type="submit"
          variant={ ButtonVariantType.action }
          className={ styles.importButton }
          dataTest="addOwnerSubmitButton"
          disabled={ submitting || (submitFailed && invalid) }
        >
          Import
        </Button>
      </div>
    </>
  );

  return (
    <StepWrapper>
      <FinalForm
        onSubmit={ handleSubmit }
        render={ renderForm }
        className={ styles.form }
        validate={ validate }
        name="addOwner"
        dataTest="addOwnerForm"
        initialValues={ initialValues }
      />
    </StepWrapper>
  );
};

export default AddOwner;
