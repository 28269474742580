import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import cn from 'classnames';

import ApplicationCard from '@/dmPortal/ui/components/Applications/ApplicationCard';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { ScheduleDemoModalWithoutHeader } from '@/Framework/UI/Organisms/ScheduleDemo';
import FeatureToggle from '@/Framework/UI/Organisms/FeatureToggle/Components';
import { RESEARCHROOM_FEATURE } from '@/dmPortal/application/config/featureToggle';
import config from '@/dataroom/application/config/config';

import styles from './applications.scss';
import spaces from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import { useNavigationContext } from '@/dmPortal/application/Layout/NavigationContext';

const Applications = () => {
  const {
    applicationCollection,
    isCollectionFetching: isApplicationCollectionFetching,
    isCollectionFetched,
    getApplications,
  } = useNavigationContext();
  const [isScheduleDemoModalVisible, setIsScheduleDemoModalVisible] = useState(false);

  useEffect(() => {
    if (isCollectionFetched) {
      getApplications();
    }
  }, []);

  const handleOpenScheduleDemoModal = () => {
    setIsScheduleDemoModalVisible(true);
  };

  const handleCloseScheduleDemoModal = () => {
    setIsScheduleDemoModalVisible(false);
  };

  return (
    <>
      <Head>
        <title>Finsight Applications | Deal Manager Portal</title>
      </Head>

      <div className={ cn(styles.wrp) }>
        <h1 className={ cn(styles.header) }>Finsight Applications</h1>
        <p className={ styles.text }>
          Finsight offers a suite of fully integrated, best-in-class applications that streamline your workflow,
          reduce costs and provide unparalleled visibility into your transaction process.
          All are backed by our Fanatical 24x7 Support. Take them for a spin or schedule a demo.
        </p>
        <div className={ styles.btnWrapper }>
          <Button
            variant={ ButtonVariantType.action }
            className={ spaces.mrn }
            title="Schedule a Demo"
            dataTest="applicationScheduleDemoButton"
            onClick={ handleOpenScheduleDemoModal }
          />
        </div>
        { isApplicationCollectionFetching && (
          <div className={ styles.spinnerWrapper }>
            <Spinner />
          </div>
        ) }

        { !isApplicationCollectionFetching &&
          applicationCollection
            .map((app, index) => {
              if (app.hidden) {
                return null;
              }
              if (app.tenant === config.tenant.tenantResearchRoom.code) {
                return (
                  <FeatureToggle
                    featureName={ RESEARCHROOM_FEATURE }
                    key={ app.tenant }
                  >
                    <ApplicationCard
                      app={ app }
                      index={ index }
                    />
                  </FeatureToggle>
                );
              }
              return (
                <ApplicationCard
                  app={ app }
                  key={ app.tenant }
                  index={ index }
                />
              );
            }) }
      </div>
      <ScheduleDemoModalWithoutHeader
        isVisible={ isScheduleDemoModalVisible }
        closeModal={ handleCloseScheduleDemoModal }
      />
    </>
  );
};

export default Applications;
