import React, { useState, Dispatch, SetStateAction } from 'react';
import { Icon, IconType } from '@dealroadshow/uikit';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import AutocompleteInput from '@dealroadshow/uikit/core/components/Input/AutocompleteInput';
import Input from '@dealroadshow/uikit/core/components/Input';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import { COMPANY_NAME_MIN_LENGTH, COMPANY_NAME_MAX_LENGTH } from '@/users/domain/company/Company';
import { ICompany } from '@/users/domain/vo/Company';
import styles from '@/allocate/ui/components/shared/Upload/LinkAccounts/LinkAccountModals/linkAccountModals.scss';

interface IProps {
  isLinkAccountModalVisible: boolean,
  linkAccountModalSelectedLei: string,
  linkAccountModalSelectedAccount: string,
  setLinkAccountModalSelectedAccount: Dispatch<SetStateAction<string>>,
  onLinkAccountModalSubmit: () => void,
  onLinkAccountModalCancel: () => void,
  getCompaniesList: (query: string) => void,
  companiesList: ICompany[],
}

const LinkAccountModals = ({
  isLinkAccountModalVisible,
  linkAccountModalSelectedLei,
  linkAccountModalSelectedAccount,
  setLinkAccountModalSelectedAccount,
  onLinkAccountModalSubmit,
  onLinkAccountModalCancel,
  getCompaniesList,
  companiesList,
}: IProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isInputTouched, setIsInputTouched] = useState(false);

  /**
   * @param {String} value
   */
  const validateAccountInput = (value) => {
    if (value.length < COMPANY_NAME_MIN_LENGTH) {
      setErrorMessage(`Suggested Account Name must contain at least ${ COMPANY_NAME_MIN_LENGTH } characters`);
      setIsInputTouched(true);
      return false;
    }
    if (value.length > COMPANY_NAME_MAX_LENGTH) {
      setErrorMessage(`Suggested Account Name may not contain more than ${ COMPANY_NAME_MAX_LENGTH } characters`);
      setIsInputTouched(true);
      return false;
    }
    setErrorMessage('');
    return true;
  };

  /**
   * @param {String} value
   */
  const handleAccountInputChange = (value) => {
    if (isInputTouched) {
      validateAccountInput(value);
      setLinkAccountModalSelectedAccount(value);
    } else {
      setLinkAccountModalSelectedAccount(value);
    }
  };

  const handleLinkAccountModalSubmit = () => {
    if (validateAccountInput(linkAccountModalSelectedAccount)) {
      onLinkAccountModalSubmit();
      setIsInputTouched(false);
    }
  };

  const handleAccountModalCancel = () => {
    setErrorMessage('');
    setIsInputTouched(false);
    onLinkAccountModalCancel();
  };

  return (
    <>
      <Modal
        className={ styles.modal }
        onCloseClicked={ handleAccountModalCancel }
        isVisible={ isLinkAccountModalVisible }
        title="Don’t see your Account?"
        dataTest="linkAccountModal"
        footer={ (
          <>
            <Button
              disabled={ !!errorMessage }
              onClick={ handleLinkAccountModalSubmit }
              variant={ ButtonVariantType.action }
              title="Submit"
              dataTest="linkAccountSubmitButton"
            />
            <Button
              variant={ ButtonVariantType.text }
              onClick={ handleAccountModalCancel }
              title="Cancel"
              dataTest="linkAccountCancelButton"
            />
          </>
        ) }
      >
        FINSIGHT maintains a master list of unique institutional Accounts to avoid fragmentation
        and provide you with clean, consolidated analytics. If you don’t see an official
        FINSIGHT Account that we can map your investor’s Legal Entity Identifier to, please
        suggest one here and we will promptly review and revert.
        <div className={ styles.modalInputs }>
          <div className={ styles.modalInputWrapper }>
            <div className={ styles.label }>Account (Orig)</div>
            { /* @ts-ignore */ }
            <Input
              name="accountLei"
              formFieldClassName={ styles.inputField }
              value={ linkAccountModalSelectedLei }
              isNarrow
              disabled
              dataTest="linkAccountModalAccountLeiInput"
            />
          </div>
          <Icon
            type={ IconType.refresh }
            className={ styles.inputRefreshIcon }
          />
          <div className={ styles.modalInputWrapper }>
            <div className={ styles.label }>Suggested Account Name</div>
            <AutocompleteInput
              // @ts-ignore
              isNarrow
              placeholder="Suggested Account Name"
              input={ {
                name: 'suggestedName',
                value: linkAccountModalSelectedAccount,
                onBlur: validateAccountInput,
                onChange: handleAccountInputChange,
              } }
              meta={ {
                error: errorMessage,
                touched: isInputTouched,
              } }
              inputClassName={ styles.inputField }
              useAsyncSuggestions
              fetchAsyncSuggestions={ (value) => getCompaniesList(value) }
              suggestions={ companiesList }
              suggestionMinLength={ 3 }
              dataTest="linkAccountModalSuggestedNameInput"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LinkAccountModals;
