import React, { ReactNode } from 'react';
import cn from 'classnames';
import RoadshowLayoutProvider from './RoadshowLayoutContext';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import headersStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import styles from './RoadshowLayout.scss';

interface IProps {
  children: ReactNode,
  name: string,
  actions?: ReactNode,
  navigations?: ReactNode,
}

const RoadshowLayout = ({ children, name, navigations, actions }: IProps) => {
  const getPageName = (className: string) => (
    <div className={ cn(styles.headerTitle, headersStyles.isH2, className) } data-test="pageHeaderTitle">
      { decodeSymbols(name) }
    </div>
  );

  return (
    <RoadshowLayoutProvider>
      <div data-test="pageHeader">
        { getPageName(styles.headerTitleDesktop) }
        <div className={ styles.header }>
          <div className={ styles.pageNavigationLinks }>
            <div className={ pageStyles.pageNavigationLinks }>{ navigations }</div>
          </div>
          <div className={ styles.actionsWrapper }>
            { getPageName(styles.headerTitleMobile) }
            { actions && <div className={ styles.actions }>{ actions }</div> }
          </div>
        </div>
      </div>
      <div>{ children }</div>
    </RoadshowLayoutProvider>
  );
};

export default RoadshowLayout;
