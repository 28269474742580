import React from 'react';
import { TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import cn from 'classnames';
import { tenantsConfig } from '@/dmPortal/application/GlobalAnalytics/config';
import dataroomConfig from '@/dataroom/application/config/config';
import GlobalAnalyticsContextProvider, {
  useGlobalAnalyticsContext,
} from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsContext';
import GlobalAnalyticsFiltersContextProvider, {
  useGlobalAnalyticsFiltersContext,
} from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import FeatureToggle from '@/Framework/UI/Organisms/FeatureToggle/Components';
import Sidebar from './common/Sidebar';
import FiltersBtn from './common/FiltersBtn';
import { Spinner } from '@dealroadshow/uikit';
import { RESEARCHROOM_FEATURE } from '@/dmPortal/application/config/featureToggle';

import headersStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from './GlobalAnalytics.scss';

interface IProps {
  children: React.ReactNode,
}

const GlobalAnalytics = ({ children }: IProps) => {
  const { isTenantActive, relativePath, isInitialized } = useGlobalAnalyticsContext();
  const { sidebarOpen } = useGlobalAnalyticsFiltersContext();

  if (!isInitialized) return <Spinner />;

  const Links = Object.values(tenantsConfig).map(({ code, path, title }) => {
    if (code === dataroomConfig.tenant.tenantResearchRoom.code) {
      return (
        <FeatureToggle
          featureName={ RESEARCHROOM_FEATURE }
          key={ path }
        >
          <NextNavLink
            key={ path }
            className={ cn({
              [styles.disabledNavLink]: !isTenantActive(code),
            }) }
            activeClassName={ pageStyles.isActiveNavLink }
            to={ relativePath(path) }
            dataTest={ `${ code }Link` }
          >
            { title }
          </NextNavLink>
        </FeatureToggle>
      );
    }
    return (
      <NextNavLink
        key={ path }
        className={ cn({
          [styles.disabledNavLink]: code === tenantsConfig.allocate.code
            ? !isTenantActive(tenantsConfig.roadshows.code)
            : !isTenantActive(code),
        }) }
        activeClassName={ pageStyles.isActiveNavLink }
        to={ relativePath(path) }
        dataTest={ `${ code }Link` }
      >
        { title }
      </NextNavLink>
    );
  });

  return (
    <>
      <div className={ cn(pageStyles.pageHeaderWithActions, spacesStyles.mbm) }>
        <div className={ cn(pageStyles.pageHeaderTitle, headersStyles.isH2) } data-test="pageHeaderTitle">
          Global Analytics
        </div>
        <div className={ pageStyles.pageHeaderActionsWrapper }>
          <FiltersBtn onClick={ sidebarOpen } />
        </div>
      </div>
      <div className={ pageStyles.pageNavigation }>
        <div className={ pageStyles.pageNavigationLinks }>
          { Links }
        </div>
      </div>
      { children }
      <Sidebar />
    </>
  );
};

export default ({ children }: IProps) => (
  <GlobalAnalyticsContextProvider>
    <GlobalAnalyticsFiltersContextProvider>
      <GlobalAnalytics>
        { children }
      </GlobalAnalytics>
    </GlobalAnalyticsFiltersContextProvider>
  </GlobalAnalyticsContextProvider>
);
