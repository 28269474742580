import React, { useState, useEffect, useCallback, memo, useMemo, useRef } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import { useGlobalAnalyticsContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsContext';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import constants from '@/Framework/UI/Templates/PortalWrp/constants';
import { Spinner, Icon, IconType } from '@dealroadshow/uikit';
import PortalWrp from '@/Framework/UI/Templates/PortalWrp';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import DatePeriod from './Sections/DatePeriod';
import Sections from './Sections/Sections';
import SharedFooter from '@/Framework/UI/Organisms/Filters/FiltersPanel/Footer';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';

import Tags from './Tags';
import { filterDealsBy } from '@/dmPortal/ui/components/GlobalAnalytics/common/Sidebar/Sections/Deals/constants';

import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import styles from '@/Framework/UI/Organisms/Filters/FiltersPanel/sidebar.scss';

const Form = memo(FinalForm);
const SUBSCRIPTION_CONFIG = { values: true };

const Sidebar = () => {
  const isDateChanged = useRef(false);
  /**
   * Created state for subscribe to form reset
   */
  const [resetTimestamp, setResetTimestamp] = useState(null);

  const {
    isAllocationsPage,
  } = useGlobalAnalyticsContext();
  const {
    isFetching,
    filtersPayload: generalFiltersPayload,
    allocationFiltersPayload,
    appliedFilters,
    dealsFilters,
    getDealsFilters,
    isZerocase: isGeneralZerocase,
    isAllocationZerocase,
    isFiltersPayload,
    getInitialState,
    getAllocationInitialState,
    getCrossDealFilters,
    getLocalFiltersState,
    applyFilters,
    resetFilters,
    getAccountFilters,
    getSponsorFilters,
    getUnderwriterFilters,
    setAppliedFilters,
    isAppliedFilters,
    isSidebarOpen,
    sidebarClose,
  } = useGlobalAnalyticsFiltersContext();

  const isZerocase = isGeneralZerocase && isAllocationZerocase;

  let defaultFilterState = {
    ...getInitialState(),
    ...getAllocationInitialState(),
  };

  const isEmptyArray = (payload) => payload.every((item) => (typeof item === 'number' ? false : isEmpty(item)));

  const filtersPayload = useMemo(() => {
    if (isAllocationsPage && !isEmptyArray(Object.values(allocationFiltersPayload))) {
      return {
        ...generalFiltersPayload,
        ...allocationFiltersPayload,
      };
    }

    return generalFiltersPayload;
  }, [generalFiltersPayload, allocationFiltersPayload, isAllocationsPage]);

  const getInitialFilterState = () => {
    const filtersState = getLocalFiltersState();
    return filtersState?.filters || defaultFilterState;
  };

  const [initialFilterState, setInitialFilterState] = useState(getInitialFilterState());
  const formRef = React.useRef(null);

  const newInitialFilterState = getInitialFilterState();
  if (!isEqual(initialFilterState, newInitialFilterState)) {
    setInitialFilterState(newInitialFilterState);
  }

  useEffect(() => {
    if (isFiltersPayload) {
      (async () => {
        await getCrossDealFilters(filtersPayload);
        if (!isAppliedFilters || isDateChanged.current) {
          getDealsFilters(filtersPayload);
          getAccountFilters(filtersPayload);
          getSponsorFilters(filtersPayload);
          getUnderwriterFilters(filtersPayload);
        }
        isDateChanged.current = false;
        setAppliedFilters(false);
      })();
    }
  }, [filtersPayload, isFiltersPayload]);

  useEffect(() => {
    if (!isSidebarOpen) {
      formRef.current?.reset(initialFilterState);
    }
  }, [isSidebarOpen]);

  const onSubmit = useCallback((values) => {
    applyFilters(values);
    setInitialFilterState(getInitialFilterState());
    sidebarClose();
    setAppliedFilters(true);
  }, [filtersPayload, applyFilters]);

  const renderFields = useCallback(({ handleSubmit, form, values }, { Field }) => {
    // next line need for reset form after closing sidebar
    formRef.current = formRef.current || form;

    const handleDateFromChange = (dateFrom) => {
      if (dateFrom) {
        isDateChanged.current = true;
        form.change('dateFrom', dateFrom);
      }
    };
    const handleDateToChange = (dateTo) => {
      if (dateTo) {
        isDateChanged.current = true;
        form.change('dateTo', dateTo);
      }
    };
    const handleReset = () => {
      resetFilters();
      setInitialFilterState(getInitialFilterState());
      isDateChanged.current = true;
      setResetTimestamp(getUnixTimestamp());
      form.reset(initialFilterState);
    };

    const handleTagRemove = (filterName) => {
      let newValue = [];
      const value = values[filterName];
      const isRange = isObject(value) && ('range' in value);

      if (isRange) {
        newValue = {
          ...value,
          range: {
            min: value.minValue,
            max: value.maxValue,
          },
        };
      }

      if (filterName === 'dates') {
        form.batch(() => {
          form.change('dateFrom', null);
          form.change('dateTo', null);
        });
      } else if (filterName === 'includeDealIds') {
        form.batch(() => {
          dealsFilters.tenants.forEach((tenant) => {
            form.change(filterDealsBy[tenant], newValue);
          });
        });
      } else if (filterName === 'upsized') {
        form.change(filterName, false);
      } else {
        form.change(filterName, newValue);
      }
    };

    return (
      <>
        <div className={ styles.sidebarHeader }>
          <div className={ styles.sidebarHeaderContent }>
            <div className={ styles.headerTitle }>
              <div className={ headerStyles.isH2 }>Filters</div>
            </div>
            <div className={ styles.headerPeriod }>
              <div className={ styles.headerPeriodWrp }>
                <DatePeriod
                  dateFrom={ values?.dateFrom }
                  dateTo={ values?.dateTo }
                  onDateFromChange={ handleDateFromChange }
                  onDateToChange={ handleDateToChange }
                />
              </div>
            </div>
            <div className={ styles.headerClose }>
              <span onClick={ sidebarClose }>
                <Icon type={ IconType.close } />
              </span>
            </div>
          </div>
        </div>
        <div className={ styles.sidebarWrp }>
          <Spinner
            overlay
            overlayClassName={ styles.spinnerOverlay }
            isVisible={ isFetching }
          />
          <Sections
            form={ form }
            formValues={ values }
            fieldComponent={ Field }
            isZerocase={ isZerocase }
            filtersPayload={ filtersPayload }
            resetTimestamp={ resetTimestamp }
          />
        </div>
        <SharedFooter
          appliedFilters={ appliedFilters }
          initialFilters={ defaultFilterState }
          filters={ values }
          tagsComponent={ Tags }
          onApply={ handleSubmit }
          onReset={ handleReset }
          onTagRemove={ handleTagRemove }
          className={ styles.footerContent }
        />
      </>
    );
  }, [isZerocase, isFetching, appliedFilters, initialFilterState]);

  const sidebarOverlayCls = cn(styles.sidebarOverlay, { [styles.isSidebarOpen]: isSidebarOpen });
  const sidebarCls = cn(styles.sidebar, { [styles.isSidebarOpen]: isSidebarOpen });

  return (
    <PortalWrp portalId={ constants.PORTAL_OVERLAY_ID }>
      <div
        className={ sidebarOverlayCls }
        onClick={ sidebarClose }
      />
      <div className={ sidebarCls }>
        <Form
          dataTest="sidebarForm"
          className={ styles.responsiveContainer }
          subscription={ SUBSCRIPTION_CONFIG }
          onSubmit={ onSubmit }
          render={ renderFields }
          initialValues={ initialFilterState }
        />
      </div>
    </PortalWrp>
  );
};

export default Sidebar;
