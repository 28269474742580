import { useEffect, useState } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import throttle from 'lodash/throttle';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/Framework/Notification';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import CompaniesRepository from '@/users/infrastructure/repository/CompaniesRepository';
import AllocationsUploadRepository from '@/allocate/infrastructure/repository/AllocationsUploadRepository';
import { SortOrder } from '@dealroadshow/uikit';
import { isScreenS } from '@dealroadshow/uikit/core/styles/screen/screen';
import { transformInitialCollection, transformCollectionForMapping } from '@/allocate/ui/components/shared/Upload/LinkAccounts/helpers';
import { ICollectionItem, ICreateAccountPayload, IAccount } from '@/allocate/domain/vo/Allocations/Upload/LinkAccounts';
import { ICompany } from '@/users/domain/vo/Company';
import {
  steps,
  getUploadUrl,
  getAllocateAnalyticsUrl,
  getDrsAnalyticsUrl,
  getDrsUploadUrl,
} from '@/allocate/application/Allocations/Upload/config';
import { tenantAllocate, tenantDealroadshow } from '@/allocate/application/config';

const useLinkAccounts = () => {
  const { push } = useRouter();
  const { container } = useDIContext();
  const allocationsUploadRepository = container.get<AllocationsUploadRepository>(AllocationsUploadRepository);
  const companiesRepository = container.get<CompaniesRepository>(CompaniesRepository);

  const {
    tenant,
    isEditing,
    dealAllocationId,
    allocationsType,
    linkedRoadshowId,
    setIsLeaveModalDisabled,
  } = useUploadContext();

  const [isFetching, setIsFetching] = useState(true);
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [leisCollection, setLeisCollection] = useState<ICollectionItem[]>([]);
  const [companiesList, setCompaniesList] = useState<ICompany[]>([]);
  const [isAllocationsImporting, setIsAllocationsImporting] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(isScreenS());

  /**
   * Get temporary allocations leis accounts mapping
   */
  const getTemporaryLeisAccountsMapping = async (): Promise<void> => {
    try {
      const response = await allocationsUploadRepository.getTemporaryLeisAccountsMapping(tenant, {
        sortOrder,
        dealAllocationId,
        allocationType: allocationsType,
        ...(tenant === tenantDealroadshow && { roadshowId: linkedRoadshowId }),
      });
      setLeisCollection(transformInitialCollection(response));
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  /**
   * Get allocations leis accounts mapping
   */
  const getLeisAccountsMapping = async (): Promise<void> => {
    try {
      const response = await allocationsUploadRepository.getLeisAccountsMapping(tenant, {
        sortOrder,
        dealAllocationId,
        allocationType: allocationsType,
        ...(tenant === tenantDealroadshow && { roadshowId: linkedRoadshowId }),
      });
      setLeisCollection(transformInitialCollection(response));
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  /**
   * Get companies list
   */
  const getCompaniesList = async (
    query: string,
  ): Promise<{
    collection: ICompany[],
    totalCount: number,
  }> => {
    try {
      const response = await companiesRepository.list({
        query,
        verified: true,
        limit: 50,
      });

      setCompaniesList(response.collection);
      return response;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      return null;
    }
  };

  /**
   * Request to create account for lei mapping
   */
  const createAccountForLeiMapping = async (payload: ICreateAccountPayload): Promise<void> => {
    try {
      await allocationsUploadRepository.createAccountForLeiMappingRequest(tenant, {
        ...payload,
        dealAllocationId,
        ...(tenant === tenantDealroadshow && { roadshowId: linkedRoadshowId }),
      });
      NotificationManager.success(getMessage(messageCodes.ACCOUNT_NAME_SUGGESTED));
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  /**
   * Map Allocations
   */
  const mapAllocations = async (): Promise<void> => {
    setIsAllocationsImporting(true);

    if (isEditing) {
      setIsLeaveModalDisabled(true);
    }

    try {
      await allocationsUploadRepository.mapAllocations(tenant, {
        leisAccountsMapping: transformCollectionForMapping(leisCollection),
        dealAllocationId,
        allocationType: allocationsType,
        ...(tenant === tenantDealroadshow && { roadshowId: linkedRoadshowId }),
      });

      if (tenant === tenantAllocate) {
        if (isEditing) {
          await push(getAllocateAnalyticsUrl(dealAllocationId));
        } else {
          await push(getUploadUrl(tenant, steps.nameAndLink));
        }
      }

      if (tenant === tenantDealroadshow) {
        if (isEditing) {
          await push(getDrsAnalyticsUrl(linkedRoadshowId));
        } else {
          await push(getDrsUploadUrl(steps.addOwner, linkedRoadshowId));
        }
      }
    } catch (error) {
      setIsAllocationsImporting(false);
      NotificationManager.error(getErrorMessage(error));
    }
  };

  /**
   * Sort accounts table
   */
  const handleSortChange = (
    sortBy: string,
    sortOrder: SortOrder,
  ): void => {
    setSortOrder(sortOrder);
    const sortFunction = sortOrder === 'asc'
      ? (a, b) => (a.lei.name > b.lei.name ? 1 : -1)
      : (a, b) => (a.lei.name < b.lei.name ? 1 : -1);
    const sortedCollection = [...leisCollection].sort(sortFunction);
    setLeisCollection(sortedCollection);
  };

  /**
   * Link account
   */
  const handleLinkAccount = (
    account: IAccount | null,
    rowIndex: number,
  ): void => {
    const updatedCollection = [...leisCollection];
    updatedCollection.splice(rowIndex, 1, {
      ...leisCollection[rowIndex],
      mappedAccount: account,
      isAutoSelected: false,
    });
    setLeisCollection(updatedCollection);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isScreenS());
    };
    window.addEventListener('resize', throttle(handleResize, 100));
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (dealAllocationId) {
      if (isEditing) {
        getLeisAccountsMapping();
      } else {
        getTemporaryLeisAccountsMapping();
      }
    }
  }, [dealAllocationId]);

  return {
    isFetching,
    isMobile,
    sortOrder,
    handleSortChange,
    getLeisAccountsMapping,
    getTemporaryLeisAccountsMapping,
    leisCollection,
    getCompaniesList,
    companiesList,
    createAccountForLeiMapping,
    mapAllocations,
    isAllocationsImporting,
    setIsAllocationsImporting,
    handleLinkAccount,
  };
};

export default useLinkAccounts;
