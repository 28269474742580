import React, { ReactNode } from 'react';
import Button from '@dealroadshow/uikit/core/components/Button';
import IconSettings from '@dealroadshow/uikit/core/components/Icon/IconSettings';
import IconPieChart from '@dealroadshow/uikit/core/components/Icon/IconPieChart';
import IconDealroadshow from '@dealroadshow/uikit/core/components/Icon/IconDealroadshow';
import IconHamburgerMenu from '@dealroadshow/uikit/core/components/Icon/IconHamburgerMenu';
import { Dropdown, DropdownItem, DropdownDivider, DropdownItemType } from '@dealroadshow/uikit/core/components/Menu/Dropdown';
import { Link } from '@/Framework/Router/Next/Link';
import drsUrl from '@/dealroadshow/infrastructure/url/drsUrl';
import { useRoadshowLayoutContext } from '../RoadshowLayout/RoadshowLayoutContext';
import styles from './RoadshowMenu.scss';

interface IProps {
  children?: ReactNode,
  isAdminAnalyticsOnly: boolean,
  roadshowId: string,
}

export const getItemDataTest = (name: string): string => `roadshowMenu-${ name }`;

const RoadshowMenu = ({ children, isAdminAnalyticsOnly, roadshowId }: IProps) => {
  const { tabMenuContent } = useRoadshowLayoutContext();

  const menuContent = (
    <>
      { !isAdminAnalyticsOnly && (
        <DropdownItem
          type={ DropdownItemType.nextNavLink }
          icon={ IconSettings }
          to={ `/roadshow/${ roadshowId }/edit` }
          dataTest={ getItemDataTest('settings') }
          NextNavLinkComponent={ Link }
        >
          Roadshow Settings
        </DropdownItem>
      ) }
      <DropdownItem
        type={ DropdownItemType.nextNavLink }
        icon={ IconPieChart }
        to={ `/roadshow/${ roadshowId }/analytics` }
        dataTest={ getItemDataTest('analytics') }
        NextNavLinkComponent={ Link }
      >
        View Analytics
      </DropdownItem>
      <DropdownItem
        type={ DropdownItemType.link }
        icon={ IconDealroadshow }
        href={ drsUrl.getUrl(`/manager/${ roadshowId }/preview`) }
        dataTest={ getItemDataTest('roadshowPreview') }
      >
        View Roadshow
      </DropdownItem>
      { children && (
        <>
          <DropdownDivider />
          { children }
        </>
      ) }
      { tabMenuContent }
    </>
  );

  return (
    <Dropdown
      dataTest="roadshowDropdownMenu"
      trigger={ (
        <Button variant="outline" className={ styles.button } dataTest="roadshowMenuToggleButton">
          <IconHamburgerMenu />
        </Button>
    ) }
    >
      { menuContent }
    </Dropdown>
  );
};

export default RoadshowMenu;
