import React, { useMemo } from 'react';
import { Link } from '@/Framework/Router/Next/Link';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import StepWrapper from '@/allocate/ui/components/shared/Upload/StepWrapper';
import useConfirmSecurityDetails from '@/allocate/ui/components/shared/Upload/ConfirmSecurityDetails/useConfirmSecurityDetails';
import SecurityDetailsHeader from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsHeader';
import SecurityDetailsTable from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsTable';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { steps, getUploadUrl } from '@/allocate/application/Allocations/Upload/config';
import styles from '@/allocate/ui/components/shared/Upload/ConfirmSecurityDetails/confirmSecurityDetails.scss';

const ConfirmSecurityDetails = () => {
  const { tenant, allocationsType, linkedRoadshowId } = useUploadContext();
  const { securityDetails, isFetching } = useConfirmSecurityDetails();

  const footerContent = useMemo(() => (
    <>
      <Link to={ getUploadUrl(tenant, steps.uploadExcel, linkedRoadshowId) }>
        <Button
          variant={ ButtonVariantType.text }
          dataTest="confirmSecurityDetailBackButton"
        >
          Back
        </Button>
      </Link>
      <Link to={ getUploadUrl(tenant, steps.confirmAllocations, linkedRoadshowId) }>
        <Button
          variant={ ButtonVariantType.action }
          dataTest="confirmSecurityDetailConfirmButton"
        >
          Confirm
        </Button>
      </Link>
    </>
  ), []);

  return (
    <StepWrapper
      isFetching={ isFetching }
      footer={ footerContent }
    >
      <div className={ styles.tableHeader }>
        <div className={ styles.title }>Security Details</div>
      </div>
      { securityDetails && (
        <div className={ styles.horizontalScrollContainer }>
          <div className={ styles.tablesScrollWrapper }>
            <SecurityDetailsHeader
              type={ allocationsType }
              securityDetails={ securityDetails }
            />
            <SecurityDetailsTable
              type={ allocationsType }
              securityDetails={ securityDetails }
            />
          </div>
        </div>
      ) }
    </StepWrapper>
  );
};

export default ConfirmSecurityDetails;
