import React, { useMemo } from 'react';

import ScheduleDemoContext, { useScheduleDemoContext } from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoContext';
import { Modal, Spinner } from '@dealroadshow/uikit';
import ScheduleDemoForm from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoForm';
import ScheduleDemoFormSuccess from './ScheduleDemoFormSuccess';

import { SCHEDULE_DEMO_MODAL_NOTES_PLACEHOLDER } from './constants';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { getTimeZoneDisplayNameDate } from '@/Framework/TimeZone/State/Redux/helpers';
import { DEFAULT_TIME_ZONE } from '@/Framework/TimeZone/State/Redux/constants';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

interface IProps {
  isVisible: boolean,
  closeModal: () => void,
}

const ScheduleDemoModal = (
  {
    isVisible,
    closeModal,
  }: IProps,
) => {
  const { currentUser } = useSessionContext();

  const {
    isSubmitting,
    isSubmitted,
    timeZoneList,
    reset,
  } = useScheduleDemoContext();

  const handleCloseModal = () => {
    closeModal();
    reset();
  };

  const scheduleDemoInitialValues = useMemo(
    () => {
      const defaultTimeZone = timeZoneList.find((timeZone) => timeZone.default) || DEFAULT_TIME_ZONE;
      return (
        {
          firstName: currentUser?.firstName || '',
          lastName: currentUser?.lastName || '',
          companyName: currentUser?.company?.name || '',
          corporateEmail: currentUser?.email || '',
          phone: currentUser?.workPhone || '',
          timeZone: timeZoneList.length
            ? {
              timeZone: defaultTimeZone.timeZone,
              value: defaultTimeZone.id,
              label: getTimeZoneDisplayNameDate(defaultTimeZone),
            }
            : null,
        }
      );
    },
    [currentUser, timeZoneList.length],
  );

  return (
    <Modal
      onCloseClicked={ handleCloseModal }
      isVisible={ isVisible }
      title={ !isSubmitted ? 'Schedule a Demo' : null }
      hideOnOverlayClicked
      dataTest="scheduleDemoModal"
    >
      <Spinner
        isVisible={ isSubmitting }
        isFixed
        overlay
      />
      {
        isSubmitted
          ? <ScheduleDemoFormSuccess closeModal={ handleCloseModal } />
          : (
            <ScheduleDemoForm
              isPublicSubmit={ false }
              // @ts-ignore
              initialValues={ scheduleDemoInitialValues }
              supportRecaptcha={ false }
              notesPlaceholder={ SCHEDULE_DEMO_MODAL_NOTES_PLACEHOLDER }
              submitButtonContainerClassName={ alignStyles.alignLeft }
            />
          )
      }
    </Modal>
  );
};

export default (props) => (
  <ScheduleDemoContext>
    <ScheduleDemoModal { ...props } />
  </ScheduleDemoContext>
);
