import React from 'react';
import demoSuccessIcon from './assets/demo-success.svg';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import styles from './scheduleDemoFormSuccess.scss';

interface IProps {
  closeModal: () => void,
}

const ScheduleDemoFormSuccess = ({ closeModal }: IProps) => (
  <div className={ styles.successContainer }>
    <img className={ styles.successIcon } src={ demoSuccessIcon } />
    <h2 className={ styles.successHeader }>
      Thank You!
    </h2>
    <p className={ styles.successText }>
      A member of our team will reach out <br /> within 20 minutes to confirm the demo.
    </p>
    <Button
      variant={ ButtonVariantType.action }
      onClick={ closeModal }
      title="Close"
      dataTest="scheduleDemoCloseButton"
    />
  </div>
);

export default ScheduleDemoFormSuccess;
