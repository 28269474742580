import styles from './uploadExcel.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import { IColumn } from '@dealroadshow/uikit';

const columns: IColumn[] = [
  {
    name: 'sheetName',
    title: 'Sheet',
    width: 180,
    cellCallback: (cellProps) => cellProps.cellData || '-',
  },
  {
    name: 'rowNo',
    title: 'Row #',
    width: 60,
    className: alignStyles.alignRight,
    cellCallback: (cellProps) => cellProps.cellData || '-',
  },
  {
    name: 'errorMessage',
    title: 'Error Description',
    className: styles.errorCell,
  },
];

export default columns;
