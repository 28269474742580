import React, { useEffect, useMemo, useCallback, memo } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { Spinner, Icon, IconType } from '@dealroadshow/uikit';
import PortalWrp, { PortalId } from '@/Framework/UI/Templates/PortalWrp';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import { useFiltersContext } from '@/dealroadshow/application/dmPortal/analytics/FiltersContext';
import DatePeriod from './Sections/DatePeriod';
import Sections from './Sections/Sections';
import SharedFooter from '@/Framework/UI/Organisms/Filters/FiltersPanel/Footer';
import SidebarTags from './SidebarTags';
import { sidebarAllowedRoutes, removingFilterKeysMapping } from '../constants';
import { getDateFromMin } from './helpers';

import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import styles from '@/Framework/UI/Organisms/Filters/FiltersPanel/sidebar.scss';

const Form = memo(FinalForm);
const SUBSCRIPTION_CONFIG = { values: true };

interface IProps {
  isOpen: boolean,
  roadshowId: string,
  onSidebarClose: () => void,
}

function Sidebar(props: IProps) {
  const {
    isOpen,
    roadshowId,
  } = props;
  const router = useRouter();

  const {
    isFetching,
    appliedFilters,
    filtersPayload,
    isZerocase,
    dates: { startedAt },
    prefetchFilters,
    getInitialState,
    getRoadshowFilters,
    applyFilters,
    resetFilters,
  } = useFiltersContext();
  const dateFromMin = getDateFromMin(startedAt);

  const isFiltersPayload = !isEmpty(filtersPayload);

  const defaultFilterState = useMemo(() => getInitialState(), [roadshowId]);

  const prevFilterState = React.useRef(appliedFilters);
  const debouncedPrefetchFilters = debounce(prefetchFilters, 200);

  // Refresh sidebar on route change.
  useEffect(() => {
    router.events.on('routeChangeComplete', fetchRoadshowFilters);
    return () => {
      router.events.off('routeChangeComplete', fetchRoadshowFilters);
    };
  }, []);

  const fetchRoadshowFilters = (pathname = router.asPath) => {
    const currentRoute = pathname.split('/').pop();
    if (isFiltersPayload && sidebarAllowedRoutes.includes(currentRoute)) {
      getRoadshowFilters(roadshowId);
    }
  };

  const onChange = ({ values }) => {
    if (!isEqual(prevFilterState.current, values)) {
      prevFilterState.current = values;
      debouncedPrefetchFilters(roadshowId, values);
    }
  };

  const onSubmit = useCallback((values) => {
    applyFilters(roadshowId, values);
    props.onSidebarClose();
    fetchRoadshowFilters();
  }, [filtersPayload]);

  const renderFields = ({ handleSubmit, form, values }, { Field, FormSpy }) => {
    const handleReset = () => {
      resetFilters(roadshowId);
      fetchRoadshowFilters();
    };

    const onTagRemove = (filterName) => {
      if (Array.isArray(removingFilterKeysMapping[filterName])) {
        if (filterName === 'dates') {
          form.batch(() => {
            form.change('dateFrom', dateFromMin);
            form.change('dateTo', null);
          });
        } else {
          form.batch(() => {
            removingFilterKeysMapping[filterName].map((filterName) => form.change(filterName, []));
          });
        }
      } else {
        form.change(filterName, []);
      }
    };

    return (
      <>
        <div className={ styles.sidebarHeader }>
          <div className={ styles.sidebarHeaderContent }>
            <div className={ styles.headerTitle }>
              <div className={ headerStyles.isH2 }>Filters</div>
            </div>
            <DatePeriod
              form={ form }
              dateFrom={ values?.dateFrom }
              dateTo={ values?.dateTo }
            />
            <div className={ styles.headerClose }>
              <span onClick={ props.onSidebarClose }>
                <Icon type={ IconType.close } />
              </span>
            </div>
          </div>
        </div>
        <div className={ styles.sidebarWrp }>
          <Spinner
            overlay
            overlayClassName={ styles.spinnerOverlay }
            isVisible={ isFetching }
          />
          <Sections
            form={ form }
            formValues={ values }
            fieldComponent={ Field }
            isZerocase={ isZerocase }
          />
        </div>
        <SharedFooter
          appliedFilters={ appliedFilters }
          initialFilters={ defaultFilterState }
          filters={ values }
          tagsComponent={ SidebarTags }
          onApply={ handleSubmit }
          onReset={ handleReset }
          onTagRemove={ onTagRemove }
          className={ styles.footerContent }
        />
        <FormSpy
          subscription={ SUBSCRIPTION_CONFIG }
          onChange={ onChange }
        />
      </>
    );
  };

  const sidebarCls = cn(styles.sidebar, { [styles.isSidebarOpen]: isOpen });
  const sidebarOverlayCls = cn(styles.sidebarOverlay, { [styles.isSidebarOpen]: isOpen });

  return (
    <PortalWrp portalId={ PortalId.PORTAL_OVERLAY_ID }>
      <div
        className={ sidebarOverlayCls }
        onClick={ props.onSidebarClose }
      />
      <div className={ sidebarCls }>
        { /* @ts-ignore */ }
        <Form
          dataTest="sidebarForm"
          className={ styles.responsiveContainer }
          subscription={ SUBSCRIPTION_CONFIG }
          initialValues={ appliedFilters }
          render={ renderFields }
          onSubmit={ onSubmit }
        />
      </div>
    </PortalWrp>
  );
}

export default React.memo(Sidebar);
